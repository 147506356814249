const hideClass = 'invisible'
const playedVideoContainerClass = 'played'

// zasobnik video kontejneru
const videoContainers = []

// vraci boolean pokud je element ve viewportu
// (staci kdyz je ho tam jen kousek)
// ale nechova se to jeste uplne idealne...
function isElementInViewport(elm) {
  const rect = elm.getBoundingClientRect()
  
  // element je cely nad hornim okrajem okna
  if (rect.top < -(rect.height)) return false

  // horni hrana elementu je pod dolni hranou okna
  if ((rect.top + rect.height) > (window.scrollY + (window.innerHeight || document.documentElement.clientHeight))) {
    return false
  }

  return true
}

// vraci element play buttonu videa
function getVideoPlayButton(container) {
  return container.parentElement.querySelector('.jsDoPlayButton')
}

// vraci elment videa zobrazujici stav watched
function getVideoWatchedInfo(container) {
  return container.parentElement.querySelector('.jsWatchedInfo')
}

// vraci video element kontejneru
function getContainerVideo(container) {
  return container.querySelector('video')
}

function showElement(elm) {
  !!elm && elm.classList.remove(hideClass)
}

function hideElement(elm) {
  !!elm && elm.classList.add(hideClass)
}

function setElementStyles(elm, styles) {
  for (const key in styles) {
    elm.style[key] = styles[key]
  }
}

// vytvoreni video elementu
function createVideoElement(src, options, styles) {
  // sestaveni options
  const opts = {
    muted: typeof options.muted != undefined ? options.muted : false,
    controls: typeof options.controls != undefined ? options.controls : false
  }

  // defaultni styly
  if (typeof styles == 'undefined') styles = {}

  // video
  const video = document.createElement('video')
  if (opts.muted == true) video.muted = true;
  if (opts.controls == true) video.controls = true;
  if (typeof styles.width == 'undefined') styles.width = '100%';
  if (typeof styles.height == 'undefined') styles.height = 'auto';
  setElementStyles(video, styles)

  // source
  const source = document.createElement('source')
  source.setAttribute('src', src)
  source.setAttribute('type', 'video/mp4')

  // vlozeni source do video
  video.append(source)

  return video

}

// inicializace video kontejneru
function initVideoMachine () {
  var containers = document.querySelectorAll('[data-video-id]');
  for (var i = 0; i < containers.length; i++) {
    const container = containers[i]
    if (!container.dataset.videoInitialized) {
      
      // neni zadane male video
      if (!container.dataset.videoSmallest) {
        hideElement(getVideoPlayButton(container))
        continue;
      }

      // kompletni video element
      const video = createVideoElement(
        container.dataset.videoSmallest,
        {muted: true})
      hideElement(video)

      // vlozeni videa do kontejneru
      container.append(video)

      // nastaveni datasetu
      container.dataset.videoInitialized = true
      container.dataset.videoContainerId = i

      // nastaveni handleru
      setContainerEventHandlers(container)

      // vlozeni kontejneru do storage
      videoContainers.push(container)

    }
  }
  videoContainers.length && initProjector()
}

// inicializace event handleru kontejneru
function setContainerEventHandlers(container) {
  // mouseenter
  container.addEventListener('mouseenter', function (e) {
    projectorPause();
    playVideoContainer(e.currentTarget.dataset.videoContainerId)
  })
  // mouseleave
  container.addEventListener('mouseleave', function (e) {
    stopVideoContainer(e.currentTarget.dataset.videoContainerId)
  })
  // click
  container.addEventListener('click', function (e) {
    showInProjector(videoContainers[e.currentTarget.dataset.videoContainerId])
  })
}

// spusti video v konjneru s id
function playVideoContainer(id) {
  const videoContainer = videoContainers[id]
  const video = getContainerVideo(videoContainer)
  videoContainer.classList.add(playedVideoContainerClass)
  showElement(video)
  video.play()

  if (!getIsContainerVideoWatched(videoContainer)) {
    hideElement(getVideoPlayButton(videoContainer ))
  }
}

// zastavi prehravani videa v kontejneru s id
function stopVideoContainer(id) {
  const videoContainer = videoContainers[id]
  const video = getContainerVideo(videoContainer)
  videoContainer.classList.remove(playedVideoContainerClass)
  hideElement(video)
  video.pause()

  if (!getIsContainerVideoWatched(videoContainer)) {
    showElement(getVideoPlayButton(videoContainer ))
  }
}

// nastavuje kontejner video jako sledovane
function setVideoContainerAsWatched(container) {
  hideElement(getVideoPlayButton(container))
  showElement(getVideoWatchedInfo(container))
  container.dataset.videoWatched = true
}

// vraci informaci o tom, jestli je kontejner video sledovane
function getIsContainerVideoWatched(container) {
  return !!container.dataset.videoWatched
}

// vraci element projektoru
function getProjector() {
  return document.querySelector('.jsProjectorContainer')
}

// vraci element videa z projektoru
function getProjectorVideo() {
  return getProjector().querySelector('video')
}

// inicializace projektoru
function initProjector() {
  // nastaveni naslouchani na scroll
  window.addEventListener('scroll', function () {
    projectorScrollHandle()
  })
  projectorScrollHandle()

  // prvotni zobrazeni videa v projektoru
  const videoContainer = document.querySelector('[data-video-to-projector]')
  showInProjector(!!videoContainer ? videoContainer : videoContainers[0])
}

// projector scroll handler
function projectorScrollHandle() {
  const projector = getProjector()
  if (!isElementInViewport(projector)) {
    projectorPause()
  }
}

// spousti video v projektoru
function projectorPlay() {
  const video = getProjectorVideo()
  video.play()
  setTimeout(function () {
    if (video.paused) {
      video.play();
    }
  }, 500);
}

// pozastavi projektor
function projectorPause() {
  if (getProjectorVideo()) {
    getProjectorVideo().pause()
  }
}

// zobrazeni videa v projektoru
function showInProjector(videoContainer) {

  // nove video do projektoru
  const newProjectorVideo = createVideoElement(
    videoContainer.dataset.videoBiggest,
    {controls: true})

  // odstraneni puvodniho videa
  if (getProjectorVideo()) getProjectorVideo().remove();

  // vlozeni noveho videa
  getProjector().append(newProjectorVideo)

  // popiska videa
  const videoCaption = document.getElementById('video-info-for-' + videoContainer.dataset.videoId)
  document.querySelector('.jsVideoInfo').innerHTML = !!videoCaption ? videoCaption.innerHTML : ""

  // spusteni videa
  projectorPlay();

  // vyscrolovani k projektoru
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })

  // nastaveni video kontejneru jako spusteneho
  setVideoContainerAsWatched(videoContainer)
}

// autostart
document.addEventListener("DOMContentLoaded", function () {
  initVideoMachine()
})
/* VideoMachine - END */